<template>
  <div class="course-list">
    <div class="header">
      <tikuHeaderTerm
        :subjectCourseSetList="subjectCourseSetList"
        :initSubjectCourseSet="subjectCourseSet"
        :initSubjectCourse="subjectCourse"
      ></tikuHeaderTerm>
    </div>
    <div class="main">
      <div class="tool">
        <!-- <button class="btn active">考点刷题</button> -->
        <button id='real-paper' :class="{btn:true, active: paperActive.truePper}" @click="practice('realPaper')">真题</button>
        <button id='model-paper' :class="{btn:true, active: paperActive.modelPaper}" @click="practice('modelPaper')" >套卷</button>
      </div>
      <!-- <div class="keypoint">
        <tikuKeypoint> </tikuKeypoint>
      </div> -->
      <div class="book-list">
        <div class="book" v-for="(item, index) in exerciseBookList" :key="index">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="tool">
            <button class="btn" @click="download(item)">下载</button>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>

    <!-- <headerMask></headerMask> -->
  </div>
</template>

<script>
// Props -》 Methods -》 Data -》Computed -》 Watch
// https://github.com/vuejs/vue/blob/dev/src/core/instance/state.js#L48-L62
import tikuHeaderTerm from "@/components/tikuHeaderTerm.vue";
import tikuKeypoint from "@/components/tikuKeypoint.vue";
import footerMenu from "@/components/footerMenu.vue";
import { courseApi } from "@/api/courseApi.js";
import { exerciseApi } from "@/api/exerciseApi.js";
import { isMobile, isWeiXin } from "@/util/browser.js";
import { Dialog, Notify, Popup } from "vant";
export default {
  components: {
    tikuHeaderTerm,
    footerMenu,
    //tikuKeypoint,
  },
  data() {
    return {
      exerciseBookList: [
        
      ],
      subjectCourseSet: {},
      subjectCourse: {},
      subjectCourseSetList: [],
      paperActive: {truePper: true, modelPaper: false},
      isMobileMode: false

    };
  },
  methods: {
    onListenTikuSubjectCourseSet() {
      this.$EventBus.$on("tikuSubjectCourseSet", (val) => {
        this.subjectCourseSet = val;
        this.subjectCourse = this.subjectCourseSet.subjectCourseList[0];
        if (this.subjectCourse) {
          const tikuType = this.paperActive['truePper'] ? 0 : 1;
          this.getExercise(this.subjectCourse["id"], tikuType);
        }
        console.log("onListenTikuSubjectCourseSet, ", this.subjectCourseSet);
      });
    },
    onListenTikuSubjectCourse() {
      this.$EventBus.$on("tikuSubjectCourse", (val) => {
        this.subjectCourse = val;
        if (this.subjectCourse) {
          const tikuType = this.paperActive['truePper'] ? 0 : 1;
          this.getExercise(this.subjectCourse["id"], tikuType);
        }
        console.log("onListenTikuSubjectCourse, ", this.subjectCourse);
      });
    },
    practice(val) {
      if (val == 'realPaper') {
        this.paperActive = { truePper: true, modelPaper: false }
        if (this.subjectCourse) {
          this.getExercise(this.subjectCourse["id"], 0);
        }
      }
      if (val == 'modelPaper') {
        this.paperActive = { truePper: false, modelPaper: true }
        if (this.subjectCourse) {
          this.getExercise(this.subjectCourse["id"], 1);
        }
      }
    },
    download(item) {
      let url = item['cdnUrl'];
      if (url.startsWith("https://")) {
        url = this.zipUrl;
      } else if (url.startsWith("http://")) {
        url = url.replaceAll("http://", "https://");
      } else {
        url = "https://" + url;
      }
      if (this.isMobileMode) {
        this.isWeixinPop = true;
        this.$nextTick(() => {
          const text = document.getElementById("copy-textarea");
          console.log("...", text);
          text.select();
          if (document.execCommand("copy")) {
            document.execCommand("copy");
            Notify({
              type: "success",
              message: "已复制:" + this.wxZipUrl,
            });
          }
        });
      } else {
        window.open(url, "_blank");
      }
    },
    getExercise(subjectCourseId, tikuType) {
      exerciseApi.exerciseBookList(subjectCourseId, tikuType).then((res) => {
        if (res.code == 0) {
          this.exerciseBookList = res.data;
        }
      })
    },
  },
  mounted() {
    courseApi.subjectCourseSetList().then((res) => {
      if (res.code == 0) {
          this.subjectCourseSetList = res.data;
          if (this.subjectCourseSetList && this.subjectCourseSetList[0]) {
            this.subjectCourseSet = this.subjectCourseSetList[0]
            this.subjectCourse = this.subjectCourseSetList[0]['subjectCourseList'][0]
            if (this.subjectCourse) {
              this.getExercise(this.subjectCourse["id"], 0);
            }
          }
        }
    })
    this.onListenTikuSubjectCourseSet();
    this.onListenTikuSubjectCourse();
    this.isMobileMode = isWeiXin();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.course-list {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    margin: 0 0 4px 0;
    flex: 0 0 auto;
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    background: #ffffff;
    border-radius: 8px 8px 0px 0px;
    margin: 12px 12px 0 12px;
    .tool {
      margin: 17px 10px 16px 10px;
      .btn {
        height: 26px;
        border-radius: 19.5px;
        font-size: 14px;
        color: #4d52d1;
        background: #ffffff;
        letter-spacing: 0.31px;
        font-weight: 600;
        border: 1px solid #4d52d1;
        padding: 0px 11px;
        margin: 0 12px 0 0;
      }
      .active {
        background: #4d52d1;
        font-size: 14px;
        color: #ffffff;
        border: none;
      }
    }
    .keypoint {
      overflow-y: scroll;
      margin: 0 0 0 10px;
    }
    .book-list {
      margin: 14px 0px 0px 0px;
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 auto;
      overflow-y: scroll;

      .book {
        width: 358px;
        height: 40px;
        margin: 7px 10px;
        background: #fafafa;
        border: 1px solid #fafafa;
        border-radius: 8px;
        border-radius: 8px;
        font-size: 14px;
        color: #323233;
        letter-spacing: 0.31px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
          margin: 12px 18px;
          font-size: 14px;
          color: #323233;
          letter-spacing: 0.31px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .tool {
          display: flex;
          justify-content: center;
          align-items: center;
          .btn {
            width: 66px;
            height: 24px;
            background: rgba(77, 82, 209, 0.9);
            border-radius: 8px;
            border-radius: 8px;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 0.27px;
            border: none;
          }
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
