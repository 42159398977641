<template>
  <div class="header-menu-mask">
    <div class="menu">
      <div class="header">
        <div class="title">
          全部考试
        </div>
        <div class="close" v-on:click="onClose">
          关闭
        </div>
      </div>
      <div class="tag-list">
        <div
          class="tag"
          v-bind:class="{ active: item.id == initSubjectCourseSet.id }"
          v-for="item in subjectCourseSetList"
          :key="item.id"
          v-on:click="goto(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  展开头部菜单
export default {
  props: {
    subjectCourseSetList: Array,
    initSubjectCourseSet: Object,
  },
  data() {
    return {
      id: 0,
    };
  },
  methods: {
    goto(item) {
      this.$EventBus.$emit("tikuSubjectCourseSet", item);
      this.onClose();
      // if (id >= 4) {
      //     this.$EventBus.$emit('headerMove', 200);
      // } else {
      //     this.$EventBus.$emit('headerMove', 0);
      // }
    },
    onClose() {
      this.$EventBus.$emit("closeTikuHeaderMask", false);
    },
  },
  mounted() {
    console.log("tikuHeaderMask mounted", this.subjectCourseSet, this.initId);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.header-menu-mask {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  .menu {
    border-radius: 0 0 16px 16px;
    height: 330px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .header {
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid rgb(239, 239, 239);
      padding: 0 17px 10px 17px;
      .title {
        font-size: 24px;
        color: #323233;
        letter-spacing: 0.53px;
        font-weight: 500;
        justify-content: flex-end;
        align-items: flex-end;
      }
      .close {
        font-size: 18px;
        color: #323233;
        letter-spacing: 0.4px;
        font-weight: 300;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
    .tag-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      letter-spacing: 0.42px;
      .tag {
        margin: 10px;
        font-size: 18px;

        color: #323233;
        letter-spacing: 0.42px;
        font-weight: 500;
        height: 38px;

        background: #f2f2f2;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 14px;
      }
      .active {
        color: #ffffff;
        background: #4d52d1;
      }
    }
  }
}
</style>
