<template>
  <div class="tiku-keypoint">
    <div class="kp" v-for="(item, index) in kpList" :key="index">
      <div class="left">
        <img class="icon-img" v-if="item.level == 1" :src="kpFirstIcon" />
        <img class="icon-img" v-else-if="item.level == 2" :src="kpSecondIcon" />
        <img class="icon-img small" v-else-if="item.level == 3" :src="kpThirdIcon" />
        <img class="icon-img tiny" v-else-if="item.level == 4" :src="kpFourthIcon" />
        <img class="icon-line-1" :src="kpdashLine12Icon" v-if="item.line == 1" />
        <img class="icon-line-2" :src="kpdashLine34Icon" v-else-if="item.line == 2" />
      </div>
      <div class="right">
        <span v-bind:class="{ small: item.level == 3 || item.level == 4 }"> {{ item.title }} </span>
      </div>
    </div>
  </div>
</template>

<script>
// Props -》 Methods -》 Data -》Computed -》 Watch
// https://github.com/vuejs/vue/blob/dev/src/core/instance/state.js#L48-L62
import tikuHeaderTerm from "@/components/tikuHeaderTerm.vue";
import footerMenu from "@/components/footerMenu.vue";
import { courseApi } from "@/api/courseApi.js";
export default {
  components: {},
  data() {
    return {
      kpFirstIcon: require("@/assets/images/tiku/kp1a.png"),
      kpSecondIcon: require("@/assets/images/tiku/kp2a.png"),
      kpThirdIcon: require("@/assets/images/tiku/kp3a.png"),
      kpFourthIcon: require("@/assets/images/tiku/kp4a.png"),
      kpdashLine12Icon: require("@/assets/images/tiku/kp1line.png"),
      kpdashLine34Icon: require("@/assets/images/tiku/kp2line.png"),
      kpList: [
        {
          id: 1,
          level: 1,
          title: "教育基础",
          line: 1,
        },
        {
          id: 1,
          level: 2,
          title: "教育目的",
          line: 1,
        },
        {
          id: 1,
          level: 3,
          title: "教育目的的理论",
          line: 1,
        },
        {
          id: 1,
          level: 4,
          title: "我国教育目的理论",
          line: 1,
        },
        {
          id: 1,
          level: 4,
          title: "西方教育目的理论",
          line: 1,
        },
        {
          id: 1,
          level: 3,
          title: "教育目的",
          line: 1,
        },
        {
          id: 1,
          level: 3,
          title: "全面发展的教育",
          line: 1,
        },
        {
          id: 1,
          level: 2,
          title: "教育的产生和发展",
          line: 1,
        },
        {
          id: 1,
          level: 1,
          title: "中学教育",
          line: 1,
        },
        {
          id: 1,
          level: 2,
          title: "教育目的",
          line: 1,
        },
      ],
    };
  },
  methods: {},
  mounted() {
    for (let i = 0; i < 20; i++) {
      this.kpList.push({
        id: 1,
        level: 2,
        title: "教育目的",
        line: 1,
      });
    }
    for (let i = 0; i < this.kpList.length - 1; i++) {
      let cur = this.kpList[i];
      let next = this.kpList[i + 1];
      if (next.level == 4 || next.level == 3) {
        cur.line = 2;
      } else {
        cur.line = 1;
      }
    }
    this.kpList[this.kpList.length - 1].line = 9999;
    console.log(this.kpList);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tiku-keypoint {  
  .kp {
    height: 38px;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20px;
      .icon-img {
        width: 16px;
        height: 16px;
      }
      .icon-line-1 {
        height: 22px;
        //width: 5px;
        margin-left: 2px;
      }
      .icon-line-2 {
        height: 26px;
        //width: 5px;
      }
      .small {
        width: 14px;
        height: 14px;
      }
      .tiny {
        width: 10px;
        height: 10px;
      }
    }
    .right {
      margin: -2px 0 0 0;
      span {
        margin: 0px 0px 0px 15px;
        font-size: 13px;
        font-weight: 500;
      }
      .small {
        margin: 0px 0px 0px 15px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
</style>
