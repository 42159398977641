import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const exerciseBookList = (subjectCourseId, tikuType) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/exercise/getDownLoadList?subjectCourseId=${subjectCourseId}&tikuType=${tikuType}`);
};

export const exerciseApi = {
  exerciseBookList: exerciseBookList,
}