<template>
  <div class="header">
    <div class="row">
      <div class="term-list" ref="termList">
        <div
          class="term"
          v-bind:class="{ active: item.id == initSubjectCourseSet.id }"
          v-for="item in subjectCourseSetList"
          :key="item.id"
          v-on:click="goto(item)"
        >
          <div>{{ item.title }}</div>
          <div v-if="item.id == initSubjectCourseSet.id" class="dot-line"></div>
        </div>
      </div>
      <div class="all-term">
        <!-- <van-icon name="apps-o" @click="isHeaderMask = true" /> -->
        <img :src="icon" @click="isHeaderMask = true" />
      </div>
    </div>
    <div class="active-subject-course" @click="isHeaderSubjectCourse = true">
      {{ initSubjectCourse.title }}
      <img style="width:8px;height:8px;margin-left:5px" :src="subjectCourseSelectIcon" />
    </div>

    <tikuHeaderMask
      :subjectCourseSetList="subjectCourseSetList"
      :initSubjectCourseSet="initSubjectCourseSet"
      v-if="isHeaderMask"
    ></tikuHeaderMask>
    <tikuHeaderSubjectCourse
      :subjectCourseList="initSubjectCourseSet.subjectCourseList"
      v-if="isHeaderSubjectCourse"
    ></tikuHeaderSubjectCourse>
  </div>
</template>

<script>
// Props -》 Methods -》 Data -》Computed -》 Watch
// https://github.com/vuejs/vue/blob/dev/src/core/instance/state.js#L48-L62

import tikuHeaderMask from "@/components/tikuHeaderMask.vue";
import tikuHeaderSubjectCourse from "@/components/tikuHeaderSubjectCourse.vue";
// 头部菜单
export default {
  props: {
    subjectCourseSetList: Array,
    initSubjectCourseSet: Object,
    initSubjectCourse: Object,
  },
  components: {
    tikuHeaderMask,
    tikuHeaderSubjectCourse,
  },
  data() {
    return {
      isHeaderMask: false,
      isHeaderSubjectCourse: false,
      icon: require("@/assets/images/header-icon.png"),
      subjectCourseSelectIcon: require("@/assets/images/tiku/tiku-subject-course-select.png"),
    };
  },
  methods: {
    goto(item) {
      this.$EventBus.$emit("tikuSubjectCourseSet", item);
    },
    onListenCloseHeaderMask() {
      this.$EventBus.$on("closeTikuHeaderMask", (val) => {
        this.isHeaderMask = val;
      });
    },
    //closeTikuSubjectCourse
    onListenCloseTikuSubjectCourse() {
      this.$EventBus.$on("closeTikuSubjectCourse", (val) => {
        this.isHeaderSubjectCourse = val;
      });
    },
    moveHeaderScroll(id) {
      this.$nextTick(() => {
        if (id >= 4) {
          setTimeout(() => {
            document.querySelector(".term-list").scrollLeft = 200;
          }, 10);
        } else {
          setTimeout(() => {
            document.querySelector(".term-list").scrollLeft = 0;
          }, 10);
        }
      });
    },
  },
  mounted() {
    console.log("tikuHeaderTerm mounted", this.subjectCourseSet);
    this.onListenCloseHeaderMask();
    this.onListenCloseTikuSubjectCourse();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.header {
  .row {
    // .big-mask {
    //   height: 100%;
    //   width: 100%;
    //   position: fixed;
    //   z-index: 2;
    //   background: rgba(0, 0, 0, 0.8);
    // }

    background: rgb(245, 246, 248);
    flex-shrink: 0;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    .term-list {
      overflow-x: scroll;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      &::-webkit-scrollbar {
        display: none;
      }
      margin: 0px 0px 0px 25px;
      .term {
        font-size: 16px;
        color: #323233;
        letter-spacing: 0.36px;
        margin: 0px 26px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex;
        align-items: center;
        .dot-line {
          height: 4px;
          width: 28px;
          background: #4d52d1;
          border-radius: 2px;
        }
      }
      .active {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: #605ddc;
        letter-spacing: 0.49px;
        padding-bottom: 4px;
        // border-bottom: 4px solid #4d52d1;
        font-weight: 600;
      }
    }
    .all-term {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 30px;
      width: 30px;
      margin: 0 17px 14px 20px;
      img {
        width: 30px;
        height: auto;
      }
    }
  }
  .active-subject-course {
    font-size: 14px;
    color: #4d52d1;
    letter-spacing: 0.31px;
    margin: 12px 0px 0px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
