<template>
  <div class="tiku-subject-course">
    <div class="tag-list">
      <div class="tag" v-for="item in subjectCourseList" :key="item.id" v-on:click="goto(item)">
        {{ item.title }}
      </div>
    </div>
    <div class="close" @click="onClose()">
      
    </div>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
export default {
  props: {
    subjectCourseList: Array,
  },
  data() {
    return {
      id: 0,
    };
  },
  methods: {
    goto(item) {
      this.$EventBus.$emit("tikuSubjectCourse", item);
      this.onClose();
      // if (id >= 4) {
      //     this.$EventBus.$emit('headerMove', 200);
      // } else {
      //     this.$EventBus.$emit('headerMove', 0);
      // }
    },
    onClose() {
      this.$EventBus.$emit("closeTikuSubjectCourse", false);
    },
  },
  mounted() {
    console.log("tikuHeaderSubjectCourse mounted", this.subjectCourseList, this.initId);
    
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tiku-subject-course {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 90px;
  z-index: 10;
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  .tag-list {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 16px 16px;
    height: 60%;
    background: #ffffff;
    overflow-y: scroll;
    .tag {
      padding: 0 26px;
      height: 41px;
      font-size: 14px;
      color: #676464;
      letter-spacing: 0.31px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid rgba(228, 228, 228, 0.5);
      flex: 0 0 auto;
      &:last-child {
        border: none;
      }
    }
  }
  .close {
    flex: 1 0 auto;
  }
}
</style>
